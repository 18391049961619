import { IonInfiniteScroll, IonInfiniteScrollContent, IonModal, IonSearchbar, IonThumbnail, IonLabel, IonItem, IonButton, IonIcon, IonContent, IonPage, IonCard, IonGrid, IonRow, IonCol } from '@ionic/react';
import './Home.css';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { NavContext } from '@ionic/react';
import Loader from '../components/Loader';
import GeoLocation from '../components/GeoLocation';
import amplitude from 'amplitude-js';
import { useDispatch, connect, useSelector, createStoreHook } from "react-redux";
amplitude.getInstance().init("0c288c769dcc3d0778eae7982ad7c005"); // Prod

const Home: React.FC = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const { navigate } = useContext(NavContext);
  const [modaldetail, setModaldetail] = useState({ 'StoreName': '', 'StoreCategory': '', 'StoreAddress': '', 'StoreUrl': '', 'StoreImage': '', 'StoreLogoFlag': '' });
  const [store_data, setData] = useState([]);
  const [list_counter, setListCounter] = useState();
  const [categorydata, setCategoryData] = useState([]);
  const overlayRef = React.useRef<HTMLInputElement>(null);
  var City = sessionStorage.getItem('City');
  var CategoryName = sessionStorage.getItem('CategoryName');
  const [visibledata, setVisibleData] = useState(10);
  const [show_notfound, setShowNotFound] = useState(false);
  const [show_reset, setShowReset] = useState(false);
  const [topsearchtext, setTopSearchText] = useState("");
  var allFlag = false;
  let allCategories = [
    "Pakaian and Accessories",
    "Makanan dan miniman",
    "Kecantikan",
    "Minimarket"
  ];

  useEffect(() => {
    if (props.city === 'default') {

      var newURL = window.location.href;
      var splitURL = newURL.toString().split("/");

      let firstParamCheck = '';
      let secondParamCheck = '';

      if (splitURL[3]) { // all or city
        firstParamCheck = (splitURL[3]).replace(/%20/g, " ");
        sessionStorage.setItem('City', firstParamCheck)
      }

      if (splitURL[4]) {
        secondParamCheck = (splitURL[4]).replace(/%20/g, " ");
        sessionStorage.setItem('CategoryName', secondParamCheck);
      }

      if (firstParamCheck === 'all' || firstParamCheck === '' || firstParamCheck === 'home') { // all
        allFlag = true;
        loadData(1, null, null);
        sessionStorage.setItem('City', '');
      } else if ((firstParamCheck !== 'all') && (firstParamCheck.length > 0) && (secondParamCheck === undefined || secondParamCheck === '')) { // city
        loadData(2, firstParamCheck, null);
        sessionStorage.setItem('City', firstParamCheck);
        sessionStorage.setItem('CategoryName', '');
      } else if ((firstParamCheck !== 'all') && (firstParamCheck.length > 0) && (secondParamCheck.length > 0)) { // city - category
        loadData(3, firstParamCheck, secondParamCheck).then(function () {
          sessionStorage.setItem('City', firstParamCheck);
          if ((isValidCategory(secondParamCheck))) {
            let categoryIndex = allCategories.indexOf(secondParamCheck);
            sessionStorage.setItem('CategoryName', secondParamCheck);
            sessionStorage.setItem('active-card-id', ("myCard" + categoryIndex));
            window.history.replaceState("", "/" + City + "/" + secondParamCheck);
            showSelectedCategory();
          } else {
            console.log('Invalid Category!');
          }
        });
      }
    } else {
      loadData(2, props.city, null);
      sessionStorage.setItem('City', props.city);
      sessionStorage.setItem('CategoryName', '');
    }

    function isValidCategory(category) {
      let foundFlag = false;

      if (allCategories.includes(category)) {
        foundFlag = true;
      }
      return foundFlag;
    }

    async function loadData(val, city, category) {
      if (val == 1) {
        // /all
        await Promise.all([getStoreData('all', null, null, null), getCategoryData()]).then(function () {
          setLoading(false);
          window.history.replaceState(null, "", "/all");
        });
      } else if (val == 2) {
        // /city
        await Promise.all([getStoreData('City', city, null, null), getCategoryData()]).then(function () {
          setLoading(false);
          window.history.replaceState(null, "", "/" + city);
        });
      } else if (val == 3) {
        // /city/category
        await Promise.all([getStoreData('CityCategory', city, category, null), getCategoryData()]).then(function () {
          setLoading(false);
          window.history.replaceState(null, "", "/" + city + "/" + category);
        });
      }
    }

  }, [props.city]);

  const openOverlay1 = () => {
    overlayRef.current?.style.setProperty("display", "block")
    setShowSearchModal(true)
  }

  const closeOverlay1 = () => {
    overlayRef.current?.style.setProperty("display", "none");
    setShowSearchModal(false);
    closeOverlay();
  }

  const openOverlay = (ev) => {
    overlayRef.current?.style.setProperty("display", "block");
    setShowModal(true);
    var modaldetail = ev;
    setModaldetail(ev);
    amplitude.logEvent('buyer_website_tap_store'); // #4 Event
  }

  const closeOverlay = () => {
    overlayRef.current?.style.setProperty("display", "none")
    setShowModal(false)
  }

  function cardClicked(e, ev: any, cardId) {
    e.stopPropagation();
    let categoryname = ev;
    let last_activeCardId = sessionStorage.getItem('active-card-id');

    // #4 Event
    amplitude.logEvent('buyer_website_tap_category', { 'CategoryName': categoryname });

    let current_activeCardId = 'myCard' + cardId;
    if (last_activeCardId && document.getElementById(last_activeCardId)) {
      if (last_activeCardId !== current_activeCardId) {
        setTimeout(() => {
          document.getElementById(last_activeCardId).classList.remove("active-card");
        }, 100);
      }
    }

    sessionStorage.setItem("CategoryName", categoryname);
    sessionStorage.setItem('active-card-id', ("myCard" + cardId));

    showSelectedCategory();

    var newURL = window.location.href;
    var splitURL = newURL.toString().split("/");

    let firstParamCheck = '';
    let secondParamCheck = '';

    if (splitURL[3]) { // all or city
      firstParamCheck = (splitURL[3]).replace(/%20/g, " ");
      sessionStorage.setItem('City', firstParamCheck)
    }

    if (firstParamCheck === 'all') {
      getStoreData('Category', null, categoryname, null);
      // window.history.replaceState(null, "", "/" + "NA" + "/" + categoryname); 
    } else {
      getStoreData('CityCategory', City, categoryname, null);
      window.history.replaceState(null, "", "/" + City + "/" + categoryname);
    }
  }

  function handleChange(e) {
    this.setState({ input: e.target.value });
  }

  function OnTopsearchClicked() {
    if ((inputRef.current?.value).length > 0) {
      getStoreData('CityCategoryStore', City, CategoryName, inputRef.current?.value);
      closeOverlay1();
      setShowReset(true);
    }

    // #7 Event
    amplitude.logEvent('buyer_website_search_store', { 'SearchKey': inputRef.current?.value });
  }

  async function categoryReset(event) {
    event.stopPropagation();
    setLoading(true);
    let city = sessionStorage.getItem('City');
    await Promise.all([getStoreData('City', city, null, null), getCategoryData()]).then(function () {
      setLoading(false);
    });

    let activeCardId = sessionStorage.getItem("active-card-id");
    if (activeCardId && document.getElementById(activeCardId)) {
      document.getElementById(activeCardId).classList.remove("active-card");
    }
  }

  async function getCategoryData() {
    await fetch("https://dev.tokowa.co/api/beli/getCategory")
      // await fetch("http://localhost:8080/getCategory")
      // await fetch("/getCategory")
      .then(res => res.json())
      .then(
        (result) => {
          setCategoryData(result.Category);
          setLoading(false);
          return Promise.resolve();
        },
        (error) => {
          return Promise.reject();
        }
      )
    return Promise;
  }

  async function getStoreData(filterby, cityVal, categoryVal, storeVal) {
    if (cityVal)
      cityVal = cityVal.replace("&", "%26");

    if (categoryVal)
      categoryVal = categoryVal.replace("&", "%26");

    if (storeVal)
      storeVal = storeVal.replace("&", "%26");

    await fetch("https://dev.tokowa.co/api/beli/getStore?filterBy=" + filterby + '&CityName=' + cityVal + '&CategoryName=' + categoryVal + '&StoreName=' + storeVal)
      // await fetch("http://localhost:8080/getStore?filterBy=" + filterby + '&CityName=' + cityVal + '&CategoryName=' + categoryVal + '&StoreName=' + storeVal)
      // await fetch("/getStore?filterBy="+filterby+'&CityName='+cityVal+'&CategoryName='+categoryVal+'&StoreName='+storeVal)
      .then(res => res.json())
      .then(
        (result) => {
          setData(result.data);
          setListCounter(result.data.length);

          if (result.data.length == 0)
            setShowNotFound(true);
          else
            setShowNotFound(false);

          return Promise.resolve();
        },
        (error) => {
          return Promise.reject();
        }
      )
    return Promise;
  }

  function navigateToStore() {
    setShowSearchModal(false);
    setShowModal(false);
    overlayRef.current?.style.setProperty("display", "none")
    // window.open("https://tokoko.id/nyemnyempengasih1");
    window.open(modaldetail.StoreUrl);

    // #6 Event
    amplitude.logEvent('buyer_website_go_to_store', { 'StoreName': modaldetail.StoreName, 'StoreCategory': modaldetail.StoreCategory });

  }

  function showSelectedCategory() {
    let activeCardId = sessionStorage.getItem("active-card-id");
    setTimeout(() => {
      document.getElementById(activeCardId).classList.add("active-card");
    }, 100);
    // }
  }

  function categoryUncheck(event, index) {
    event.stopPropagation();

    sessionStorage.setItem('CategoryName', '');

    let selectedCardId = 'myCard' + index;
    setTimeout(() => {
      document.getElementById(selectedCardId).classList.remove("active-card");
      sessionStorage.setItem('active-card-id', '');
    }, 100);
    reset();
  }

  async function reset() {
    // if City exists then load city wise else load all
    if (City.length > 0) {
      await Promise.all([getStoreData('City', City, null, null), getCategoryData()]).then(function () {
        // await Promise.all([getStoreData('CityCategory', City, CategoryName, null), getCategoryData()]).then(function () {
        setLoading(false);
        setShowReset(false);
      });
    } else {
      await Promise.all([getStoreData('all', null, null, null), getCategoryData()]).then(function () {
        // await Promise.all([getStoreData('CityCategory', City, CategoryName, null), getCategoryData()]).then(function () {
        setLoading(false);
        setShowReset(false);
      });
    }

    let activeCardId = sessionStorage.getItem("active-card-id");
    setTimeout(() => {

      if (activeCardId) {
        document.getElementById(activeCardId).classList.remove("active-card");
      }
    }, 100);
  }

  const setSearch = function (evt: any) {
    setTopSearchText(evt.target.value);
  };

  function loadMoreData($event) {
    setVisibleData((prevValue) => prevValue + 10)
    setTimeout(() => {
      $event.target.complete();
      setVisibleData((prevValue) => prevValue + 10);
      if (store_data.length == 2000) {
        $event.target.disabled = true;
      }
    }, 500);
  }

  return (
    <IonPage>
      <Loader lodingData={loading} />
      <div onClick={closeOverlay1} className="cust-01-page" ref={overlayRef}>
      </div>

      <GeoLocation />

      {/* Gray Box Container */}
      <IonContent className="gray-box">
        {/* Search Bar */}
        {/* Green Radius Container */}
        <IonContent>
          <IonContent className={loading ? 'hidden' : ''}>
            <IonGrid className="green-box">
              {/* Text 1 */}
              <IonRow className="ion-margin">
                <IonCol size="8" className="ionlabel">
                  <IonLabel className="title-1">Menampilkan toko terbaik</IonLabel>
                </IonCol>
                <IonCol onClick={openOverlay1} offset="2" >
                  <IonIcon src="./assets/icon/search.svg" color="primary" placeholder="Enter Drop Location" className="search-icon" />
                </IonCol>
              </IonRow>
              {/* Text 2 */}
              <IonRow>
                <IonCol size="8" className="ionlabel city-align">
                  <IonLabel className="title-2">{City}</IonLabel>

                </IonCol>
                <IonCol size="2">
                  {/* <IonButton onClick={() => { navigate('/location-manual'); return 0 }} className="btn-ganti" fill="outline">Ganti</IonButton> */}
                  <IonButton onClick={() => {
                    navigate('/kota', amplitude.logEvent('buyer_website_change_location') // #3 Event
                    ); return 0
                  }} className="btn-ganti" fill="outline">Ganti</IonButton>
                </IonCol>
              </IonRow>
              {/* 3 Cards */}
              <div className="scrollmenu">
                {categorydata.map((val, i) => {
                  return (
                    <React.Fragment key={i}>
                      <IonCol>
                        <IonCard className="myCard" id={"myCard" + i} onClick={(event) => cardClicked(event, val.CategoryName, i.toString())} >
                          <span onClick={(event) => categoryUncheck(event, i)} className="card-close-btn-outer">
                            <IonIcon name="close" className="close-icon-btn"></IonIcon>
                          </span>
                          <img src={`./assets/images/${val.CategoryImage}`} />
                          <div className="myOverlay text-center">
                            <div className="card-title">{val.CategoryName}</div>
                          </div>
                        </IonCard>
                      </IonCol>
                    </React.Fragment>
                  )
                })}
              </div>

              <IonRow className="gray-border">
                {/* <IonCol className="dki-margin" size="12">
                <span className="gray-color">Terdapat </span><b className="black-color">{list_counter} toko {CategoryName}</b> <span className="gray-color">di </span><span><b className="txt-uppercase">{City}</b></span>
                </IonCol> */}

                {/* <div className="reset-list">
                  <div className={show_reset ? "reset-padding" : ''}>
                    <span className="gray-color">Terdapat </span><b className="black-color">{list_counter} toko {CategoryName}</b> <span className="gray-color">di </span><span><b className="txt-uppercase">{City}</b></span>
                  </div>
                  {
                    show_reset ?
                      <div className="reset-button" onClick={() => reset()}>
                        Reset
              </div> :
                      null
                  }
                </div> */}

                {

                  show_reset ?
                    <div className="reset-list">
                      <div>
                        <span className="gray-color">Hasil pencarian</span>
                        <b className="black-color"> "{topsearchtext}"</b>

                      </div>
                      <div className="reset-button" onClick={() => reset()}>
                        Reset
</div>
                    </div>
                    :
                    <div className="reset-list">
                      <div>
                        {/* <span className="gray-color">Terdapat </span><b className="black-color">{list_counter} toko </b> <span className="gray-color">di </span><span><b className="txt-uppercase">{City}</b></span> */}
                        <span className="gray-color">Terdapat </span><b className="black-color">{list_counter} toko {CategoryName}</b> <span className="gray-color">di </span><span><b className="txt-uppercase">{City}</b></span>
                      </div>
                    </div>
                }

              </IonRow>
              {
                show_notfound ?
                  <div>
                    <img src="./assets/images/product-not-found.svg" ></img>
                    <div className="not-found">Tidak ditemukan toko dengan hasil pencarian "{topsearchtext}"</div>
                  </div>

                  :

                  null
              }

              {/* Product List */}
              {/* {store_data.slice(0,visibledata).map((val, i) => { */}
              {store_data.slice(0, visibledata).map((val, i) => {
                return (
                  <IonRow key={i}>
                    <IonCol className="list-items" size="12">
                      <IonItem lines="none" onClick={() => openOverlay(val)} className="action-sheets-basic-page">
                        {
                          (val.StoreLogoFlag == 1) ?
                            <IonThumbnail className="list-thumbnail" slot="start">
                              <img src={val.StoreImage} />
                            </IonThumbnail>
                            :
                            <IonThumbnail className="list-thumbnail home" slot="start">
                              <span className="no-img">{val.StoreName.match(/(\b\S)?/g).join("").substring(0, 2).toUpperCase()}</span>
                              {/* <span className="no-img">{val.StoreName.match(/(\b\S)?/g).join("").toUpperCase() }</span>  */}
                              {/* <span className="no-img">{val.StoreName.substring(0,2).toUpperCase() }</span> */}
                            </IonThumbnail>
                        }
                        <div>
                          <IonLabel className="margin-list item-text1">{val.StoreName}</IonLabel>
                          <IonLabel className="item-text2">{val.StoreCategory}</IonLabel>
                          <IonLabel className="item-text3">{val.StoreAddress}</IonLabel>
                        </div>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                )
              })}

              <IonInfiniteScroll threshold="100px" onIonInfinite={(e) => loadMoreData(e)}>
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data...">
                </IonInfiniteScrollContent>
              </IonInfiniteScroll>

              {/* {
                show_notfound ? 
                '' : <IonButton color="secondary" onClick={() => loadMoreData()}>Load More..</IonButton> 
              } */}

              <div className="loadMore">

              </div>


            </IonGrid>
          </IonContent>

          {/* Modal Popup Code Details*/}
          <IonModal isOpen={showModal} cssClass='my-custom-class'>
            <IonContent className="popup">
              <IonIcon onClick={closeOverlay} name="close" className="mdlcls1"></IonIcon>
              <IonContent text-center className="gray-border-bottom">

                {/* <img className="list-thumbnail display-block" src="assets/images/Nyem Nyem Pengasih.jpeg" width="12" /> */}

                {
                  (modaldetail.StoreLogoFlag == '1') ?
                    <img className="list-thumbnail display-block" src={modaldetail.StoreImage} width="12" />
                    :
                    <span className="modal-no-img">
                      {modaldetail.StoreName.match(/(\b\S)?/g).join("").toUpperCase()}
                    </span>
                }

                <IonLabel className="margin-list item-text1 display-block">{modaldetail.StoreName}</IonLabel>
                <IonLabel className="item-text2 display-block gray-border-bottom">{modaldetail.StoreCategory}</IonLabel>
                <IonLabel className="margin-list item-text4 display-block">{modaldetail.StoreAddress}</IonLabel>
                {/* <IonButton id="nvgbutton" onClick={() => navigateToStore()} className="display-block btn-modal">Kunjungi Toko</IonButton> */}
                <div className="button1">
                  <IonButton id="nvgbutton" onClick={() => navigateToStore()} className="display-block btn-modal">Kunjungi Toko</IonButton>
                </div>
              </IonContent>
            </IonContent>
          </IonModal>


          <IonModal isOpen={showSearchModal} cssClass='home-search-modal'>
            <IonContent className="popup">
              <div className="searchbar">
                {/* <div className="">
                  <IonIcon onClick={closeOverlay1} name="close" className="mdlcls"></IonIcon>
                </div> */}
                <div className="searchbarlist">
                  {/* <IonSearchbar value='' ref={inputRef} onChange={() => { handleChange(this) }} className="search" showCancelButton="focus" placeholder="Cari Toko di DKI JAKARTA">
                    <IonButton color="#009688" className="test" onClick={() => { OnTopsearchClicked() }}>Cari</IonButton>
                  </IonSearchbar> */}

                  <IonSearchbar ref={(inputRef)} onIonChange={setSearch} className="search" searchIcon="./assets/icon/location-icon.png" showCancelButton="focus" placeholder="Cari Toko di DKI JAKARTA">
                    <IonButton color="#009688" className="test" onClick={() => { OnTopsearchClicked(inputRef) }}>Cari</IonButton>
                  </IonSearchbar>

                </div>
              </div>
            </IonContent>
          </IonModal>

        </IonContent>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = state => {
  return {
    city: state.city
  };
};

export default connect(mapStateToProps, '')(Home);