import { get } from '@ionic-native/core/decorators/common';
import { Geolocation, GeolocationOriginal, Geoposition } from '@ionic-native/geolocation';
import { IonButton, IonLoading, IonToast } from '@ionic/react';
import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from 'react';
import { NavContext } from '@ionic/react';
import { useHistory } from 'react-router';
import amplitude from 'amplitude-js';
import { useDispatch, connect, useSelector, createStoreHook } from "react-redux";
import reducer from '../rootreducer';

amplitude.getInstance().init("0c288c769dcc3d0778eae7982ad7c005"); // Prod
// amplitude.getInstance().init("881ca9b2647eb17f48f55fea5e915b33"); // Test

interface LocationError {
  showError: boolean;
  message?: string;
}

const GeoLocation: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<LocationError>({ showError: false });
  const [position, setPosition] = useState<Geoposition>();
  const { navigate } = useContext(NavContext);
  const [data, setData] = React.useState(null);
  const mountedRef = useRef(true);
  const history = useHistory();
  const dispatch = useDispatch();
  var x;

  useEffect(() => {
    let locationFlag = sessionStorage.getItem('manual-location');
    if(!(locationFlag == 'true')) {
      navigator.geolocation.getCurrentPosition(loc);
      getLocation();
    } else {
      console.log('Not Manual Location!');
    }
  }, []);

  function updateCity(city) {
    dispatch({
      type: 'UPDATE_CITY',
      value: city,
    });
  }

  const get = async () => {
    setLoading(true);
    try {
      const position = await Geolocation.getCurrentPosition();
      const latitude = position.coords.latitude
      const longitude = position.coords.latitude
      setPosition(position);
      setLoading(false);
    } catch (e) {
      setError({ showError: true, message: e.message });
      setLoading(false);
    }
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      console.log("Geolocation is not supported by this browser.")
    }
  }

  function showPosition(position) {
    x = "Latitude: " + position.coords.latitude +
      "<br>Longitude: " + position.coords.longitude;
    console.log(x);
    sessionStorage.setItem('location-denied', 'false');
  }

  function showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        sessionStorage.setItem('location-denied', 'true');
        sessionStorage.setItem('City', '');

        let city = sessionStorage.getItem('City');
        if (city == '' || city == null)
          navigate('/kota');
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.")

        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.")

        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.")
        break;
    }
  }

  const loc = async (position: any) => {
    const { latitude, longitude } = position.coords;
    await fetch("https://dev.tokowa.co/tokoko/address?latitude=" + latitude + '&longitude=' + longitude)
      .then(res => res.json())
      .then(
        (result) => {
          let city = result.city;
            sessionStorage.setItem("City", city);
            // #1 Event
            amplitude.logEvent('buyer_website_allow_location');

            setTimeout(() => {
              if (city == '' || city == null) {
                navigate('/kota');
              } else {
                updateCity(city);
                navigate('/'+city);
              }
            }, 1000);
          // }
        });
  }

  return (
    <>
      <IonLoading
        isOpen={loading}
        onDidDismiss={() => setLoading(false)}
        message={'Getting Location...'}
      />
      <IonToast
        isOpen={error.showError}
        onDidDismiss={() => setError({ message: "", showError: false })}
        message={error.message}
        duration={3000}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
      city: state.city
  };
};

export default connect(mapStateToProps, '') (GeoLocation);