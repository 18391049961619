import { IonInfiniteScroll, IonInfiniteScrollContent, IonModal, IonSearchbar, IonThumbnail, IonLabel, IonItem, IonButton, IonIcon, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonGrid, IonRow, IonCol, IonToggle, IonList, IonItemDivider, IonSlides, IonSlide, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent } from '@ionic/react';
import './ManualLocation.css';
import React, { useState, useContext, useEffect } from 'react'
import Loader from '../components/Loader';
import { NavContext } from '@ionic/react';
import amplitude from 'amplitude-js';
import { useDispatch, connect, useSelector, createStoreHook } from "react-redux";
import reducer from '../rootreducer';

amplitude.getInstance().init("0c288c769dcc3d0778eae7982ad7c005"); // Prod

const ManualLocation: React.FC = (props) => {

    const [searchText, setSearchText] = useState("");
    const [checked, setChecked] = useState(false);
    const { navigate } = useContext(NavContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [location_data, setLocationData] = useState([]);
    const [visibledata, setVisibleData] = useState(10);
    const dispatch = useDispatch();

    function updateCity(city) {
        dispatch({
          type: 'UPDATE_CITY',
          value: city,
        });
    }

    useEffect(() => {
        void async function () {
            await getLocationData().then(function () {
                setLoading(false);
            })
        }();
    }, []);

    async function getLocationData() {
        await fetch("https://dev.tokowa.co/api/beli/getCity")
            //  await fetch("http://localhost:8080/getCity")
            //  await fetch("/getCity")
            .then(res => res.json())
            .then(
                (result) => {
                    const location = result;
                    setLocationData(result.City);
                    setLoading(false);
                    return Promise.resolve();
                },
                (error) => {
                    return Promise.reject();
                }
            )
        return Promise;
    }

    const setSearch = function (evt: any) {
        setSearchText(evt.target.value);
    };

    const setSelectedSearchData = function (ev: any) {
        const selectedsearchdata = ev;
        sessionStorage.setItem("City", selectedsearchdata);
        sessionStorage.setItem('location-denied', 'false');
        sessionStorage.setItem('manual-location', 'true');
        // #2 Event
        amplitude.logEvent('buyer_website_manual_location', {'LocationName': selectedsearchdata});

        let city = sessionStorage.getItem('City');

        updateCity(city);        

        navigate('/');
    }
    
    function loadMoreData($event) {
        setVisibleData((prevValue)=> prevValue + 10);
         setTimeout(() => {
          $event.target.complete();
          setVisibleData((prevValue)=> prevValue + 10);
          // App logic to determine if all data is loaded
          // and disable the infinite scroll
          if (location_data.length == 1000) {
            $event.target.disabled = true;
          }
        }, 500);
    }

    return (
        <IonPage>
            <Loader lodingData={loading} />
            {/* Gray Box Container */}
            <IonContent className={loading ? 'hidden' : 'gray-box'}>
                {/* Green Radius Container */}
                <IonContent>
                    <IonContent>
                        <IonGrid className="green-box location">
                            {/* Text 1 */}
                            <IonRow className="ion-location-margin">
                                <IonCol size="12" className="title">
                                    <IonLabel className="title-1">Cari Area Anda</IonLabel>
                                </IonCol>
                            </IonRow>
                            {/* <IonSearchbar showCancelButton="focus" onIonChange={setSearch} placeholder="Cari provinsi Anda disini" className="location" color="black"></IonSearchbar> */}
                            <IonRow className="bar">
                                <IonSearchbar searchIcon="./assets/icon/location-icon.png" onIonChange={setSearch} placeholder="Cari Area Anda Disini" className="location" color="black"></IonSearchbar>
                            </IonRow>
                            {/* value="" */}

                            <IonRow className="ion-location-margin">
                                <IonCol size="12" className="titles">
                                    <IonLabel className="title-1">Atau pilih kota yang terdaftar</IonLabel>
                                </IonCol>
                            </IonRow>

                            {/* Product List */}
                            {location_data.slice(0,visibledata).filter((val) => {
                            // {location_data.filter((val) => {
                                if (searchText == "") {
                                    return val
                                }
                                else if (val.Name.toLowerCase().includes(searchText.toLowerCase())) {
                                    return val
                                }
                            }).map((val, i) => {
                                return (
                                    <IonRow id="myUL" key={i} className="list" onClick={() => setSelectedSearchData(val.Name)}>
                                        <IonCol size="12" className="border-radius-10">

                                            <IonItem className="no-spacing" lines="none">
                                                <IonThumbnail className="location-thumbnail" slot="start">
                                                    <img className="location-style" src="./assets/icon/location.svg" />
                                                </IonThumbnail>

                                                <div className="scrolling" >
                                                    <div>
                                                        <IonLabel className="margin-list ">{val.Name}</IonLabel>
                                                    </div>
                                                </div>
                                            </IonItem>
                                        </IonCol>
                                    </IonRow>
                                )
                            })}

            <IonInfiniteScroll threshold="100px" onIonInfinite={(e) => loadMoreData(e)}>
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data...">
                </IonInfiniteScrollContent>
              </IonInfiniteScroll>

                        </IonGrid>
                    </IonContent>
                </IonContent>
            </IonContent>
        </IonPage>
    );
};

const mapStateToProps = state => {
    return {
        city: state.city
    };
};

export default connect(mapStateToProps, '') (ManualLocation);