const initialState = {
    city : 'default'
}

const reducer = (state = initialState, action) => {
    const newState = {...state};

    switch(action.type) {
        case 'UPDATE_CITY':
            newState.city = action.value;
            sessionStorage.setItem('City', action.value);
            break;
        }
    return newState;
}   

export default reducer;